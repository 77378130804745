import { formatDate, formatDateICU } from '@wix/communities-blog-client-common';
import { WixCodeApi } from '../../external/common/types';

export class DateFormatter {
  constructor(private readonly wixCodeApi: WixCodeApi) {}

  format(date: string) {
    return formatDate({
      date,
      lng: this.wixCodeApi.site.language,
      timezone: this.wixCodeApi.site.timezone,
    });
  }

  formatICU(date: string) {
    return formatDateICU({
      date,
      rtfFormatter: '',
      lng: this.wixCodeApi.site.language,
      timezone: this.wixCodeApi.site.timezone,
    });
  }

  formatTimeToRead(amountOfMinutes: number) {
    return `${amountOfMinutes} min read`;
  }
}
