import {
  IWixAPI,
  InitAppForPageFn,
  ViewerScriptFlowAPI,
} from '@wix/yoshi-flow-editor';
import { PostPageRenderModel } from '../external/blocks-widget/post-page-render-model';
import { getBaseURL } from './base-url';
import { fetchMetadata } from './metadata';
import { getPostAndCategories } from './post';

interface FetchInitialDataParams {
  wixCodeApi: IWixAPI;
  initParams: Parameters<InitAppForPageFn>[0];
  flowAPI: ViewerScriptFlowAPI;
}

export const fetchInitialData = async ({
  wixCodeApi,
  initParams,
  flowAPI,
}: FetchInitialDataParams): Promise<PostPageRenderModel> => {
  const baseURL = getBaseURL({ wixCodeApi });
  const { post, categories } = await getPostAndCategories({
    wixCodeApi,
    initParams,
    baseURL,
    flowAPI,
  });

  const metadata = await fetchMetadata({
    flowAPI,
    baseURL,
    postId: post.id,
  });

  return new PostPageRenderModel({
    post,
    tags: post.tags,
    metadata,
    allCategories: categories,
  });
};
