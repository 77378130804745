import { AppData } from '../../viewer.app';
import { CounterController } from './counter-controller';
import model from './model';

type CreateController = Parameters<(typeof model)['createController']>[0];

const createController = (({ appData, flowAPI, $w }) => ({
  pageReady: async () => {},
  exports: new CounterController($w, flowAPI, appData as AppData),
})) satisfies CreateController;

export type PostCountersApi = ReturnType<typeof createController>['exports'];

export default model.createController(createController);
