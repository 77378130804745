import { IWixAPI } from '@wix/yoshi-flow-editor';
import { GLOBALS } from '@wix/communities-blog-client-common';
import { isEditorOrPreview } from './selectors';

interface GetBaseUrlParams {
  wixCodeApi: IWixAPI;
}

export const getBaseURL = ({ wixCodeApi }: GetBaseUrlParams) =>
  isEditorOrPreview(wixCodeApi)
    ? GLOBALS.API_BASE_URL.replace('_api/communities-blog-node-api', '')
    : wixCodeApi.location.baseUrl;
