import type { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { likePost, unlikePost } from '@wix/ambassador-blog-v3-post/http';
import { raise } from '@wix/communities-blog-client-common';
import { blogAppDefId } from '../common/constants/apps';
import { SANTA_MEMBERS_APP_ID } from '../common/constants/members-area';
import { TPA_PAGE_ID_BLOG } from '../common/constants/tpa-pages';
import { NormalizedPost } from '../common/types';

export class BlocksWidgetFacade {
  constructor(private readonly flowAPI: PlatformControllerFlowAPI) {}

  async navigateToCategoryFeed(slug: string) {
    const url = await this.makeCategoryFeedUrl(slug);
    this.navigateToFeed(url);
  }

  async navigateToTagFeed(slug: string) {
    const url = await this.makeTagFeedUrl(slug);
    this.navigateToFeed(url);
  }

  async likePost(postId: string) {
    return this.flowAPI.httpClient.request(likePost({ postId }));
  }

  async dislikePost(postId: string) {
    return this.flowAPI.httpClient.request(unlikePost({ postId }));
  }

  async navigateToAuthorProfile(post: NormalizedPost) {
    const memberId =
      post.owner?.siteMemberId || raise('"Post.owner.siteMemberId" not found');
    const memberSlug = post.owner?.slug || raise('"Post.owner.slug" not found');

    this.flowAPI.controllerConfig.wixCodeApi.site
      .getPublicAPI(SANTA_MEMBERS_APP_ID)
      .then((api) => {
        api.navigateToMember({
          memberId,
          memberSlug,
        });
      });
  }

  private async makeCategoryFeedUrl(categorySlug: string) {
    const { url } =
      await this.flowAPI.controllerConfig.wixCodeApi.site.getSectionUrl({
        sectionId: TPA_PAGE_ID_BLOG,
        appDefinitionId: blogAppDefId,
      });
    if (url) {
      return `${url}/categories/${categorySlug}`;
    }
    return;
  }

  private async makeTagFeedUrl(tag: string) {
    const { url } =
      await this.flowAPI.controllerConfig.wixCodeApi.site.getSectionUrl({
        sectionId: TPA_PAGE_ID_BLOG,
        appDefinitionId: blogAppDefId,
      });
    if (url) {
      return `${url}/tags/${tag}`;
    }
    return;
  }

  private async navigateToFeed(url?: string) {
    if (!url) {
      return;
    }
    this.flowAPI.controllerConfig.wixCodeApi.location.to?.(url);
  }
}
