import type { AppData } from '../../viewer.app';
import type { PostCategoriesApi } from '../Post Categories/viewer.controller';
import { PostCountersApi } from '../Post Counters New/viewer.controller';
import type { PostMetadataApi } from '../Post Metadata/viewer.controller';
import type { PostTagsApi } from '../Post Tags/viewer.controller';
import blocksModel from './model';

export default blocksModel.createController((params) => {
  const { $w } = params;
  const appData = params.appData as AppData;

  return {
    pageReady: async () => {
      const model = await appData.fetchPostPageRenderModel();

      const ui = {
        categories: $w('#categories1') as PostCategoriesApi,
        tags: $w('#tags1') as PostTagsApi,
        metadata: $w('#metadata1') as PostMetadataApi,
        counters: $w('#postCountersNew1') as PostCountersApi,
      };

      ui.categories.initialize(model);
      ui.tags.initialize(model);
      ui.metadata.initialize(model);
      ui.counters.initialize(model);
    },
    exports: blocksModel.getExports(),
  };
});
